<template>
    <div>
        <h1>{{ title }}</h1>
        <p>{{ message }}</p>
        <div class="icon">
            <img src="https://s3.amazonaws.com/ims-site/logos/deluxeBrandMarketing/powered-156x35.png">
        </div>
        <p class="smaller">v{{ version }}</p>
    </div>
</template>

<style scoped>
h1 {
    margin-top: 100px;
}
.icon {
    margin-top: 100px;
}
.smaller {
    font-size: 10px;
    text-align: center;
}
</style>

<script>
import util from '../lib/util';
import axios from "axios";

export default {
    data () {
        return {
            title: 'Welcome',
            message: '',
            die_on_error: false, // j2 requested redir anyway; do not redirect if an error happens, to debug, contrary to more forgiving prod behavior of redir
            debug_death: false, // do not redirect no matter what happens, so console can be read, etc
            version: '1.0.19',
            status_check_interval: 2000 // 2 seconds = 2000
        }
    },
    created () {
        // console.log('created');
        this.init(()=> { // dont care what init returns, is fake
            const t = this;
            const f = () => {
                t.updateMessage();
                t.getStatus((s)=>{
                    // debug
                    console.log('status',s);

/*

j2 response


{
    "is_loading": true,
    "pending_tasks": [
        {
            "type": "LISTING_LOAD",
            "message": "listing update completed",
            "status": "pending"
        }
    ],
    "completed_tasks": [
        {
            "type": "LISTING_LOAD",
            "message": "listing update completed",
            "status": "complete"
        },
        {
            "type": "LISTING_LOAD",
            "message": "listing update completed",
            "status": "complete"
        }
    ],
    "status": "ok"
}

*/


                    const redir = (e) => {
                        console.log('redir');
                        // return;
                        const default_redir = 'https://www.impactorder.com:444/themes/smx/p.asp';
                        if (s && s.landing_page && s.landing_page.length > 5) {
                            let lander = unescape(s.landing_page);
                            // like https://www.impactorder.com/site/goto/item/listings
                            console.log(`redirect to landing page ${lander}`);
                            if (t.debug_death) {
                                console.log(`debug death`);
                            } else {
                                location.href = default_redir + '?redirect=' + lander;
                            }
                        } else if (e && s && s.redir_fail && s.redir_fail.length > 5) {
                            let lander = unescape(s.redir_fail);
                            // like https://www.impactorder.com/site/goto/item/listings
                            console.log(`redirect to error page ${lander}`);
                            if (t.debug_death) {
                                console.log(`debug death`);
                            } else {
                                location.href = lander;
                            }
                            
                        } else {
                            console.log(`redirect to default ${default_redir}`);
                            if (t.debug_death) {
                                console.log(`debug death`);
                            } else {
                                location.href = default_redir;
                            }
                            
                        }
                        return 0;
                    };                    
                    // error
                    if (!s || !s.status || s.status.toLowerCase()!='ok') {
                        console.log('error handling...');
                        t.updateMessage('Sorry, there was an error.');
                        let err = 'non ok status';
                        try {
                            if (s.error) {
                                console.log(s.error,s);
                                err = s.error;
                                if (t.die_on_error) {
                                    console.log('die on error');
                                    return;
                                }
                            }
                            if (s.error_message) {
                                err = s.error_message;
                                console.log(s.error_message,s);
                                if (t.die_on_error) {
                                    console.log('die on error');
                                    return;
                                }
                            }
                        } catch (e) {
                            console.log('trouble reading response',e);
                            err = 'trouble reading response';
                            if (t.die_on_error) {
                                console.log('die on error');
                                return;
                            }
                        }
                        return redir(err);
                    }
                    // main task is completed
                    if (s.completed_tasks) {
                        console.log('search for completed tasks...');
                        for (let i=0; i < s.completed_tasks.length; i++) {
                            if (s.completed_tasks[i].type && s.completed_tasks[i].type=='LISTING_LOAD') {
                                console.log('listing load is completed, yay');
                                t.updateMessage('Done! One moment...');
                                return redir();
                            }
                        }
                    }
                    // not loading
                    if (!s.is_loading) {
                        console.log('not loading, so redir');
                        return redir();
                    }
                    // every 2 seconds, check status again
                    console.log('queue status check in milli',t.status_check_interval);
                    setTimeout(()=> {
                        f();
                    },t.status_check_interval);
                });
            }
            f();
        });
    },
    methods: {
        init (cb) {
            console.log('init v'+this.version);
            const responses = {
                'submitted': {
                    'status': 'submitted'
                },
                'error': {
                    'status': 'error',
                    'error': 'No Session'
                }
            };
            // ok as is since there is no init endpoint
            setTimeout(()=>{
                cb(responses.submitted);
            },1000);
        },
        async getStatus (cb) {
            console.log('get status');
            
            // const responses = {
            //     'error': {
            //         "error_message": "You've provided an invalid AMADEL session",
            //         "error": "Invalid arguments",
            //         "status": "err"
            //     },
            //     'complete': {
            //         "pending_tasks": [
            //             {
            //                 "type": "LISTING_LOAD",
            //                 "message": "listing update completed",
            //                 "status": "pending"
            //             }
            //         ],
            //         "is_loading": true,
            //         "landing_page": 'https://www.impactorder.com/site/goto/item/listings',
            //         "completed_tasks": [
            //             {
            //                 "type": "PROFILE_UPDATE",
            //                 "message": "RE/Max PAPI profile synchronization completed",
            //                 "status": "complete"
            //             },
            //             {
            //                 "type": "GALLERY_LOAD",
            //                 "message": "Gallery synchronization completed",
            //                 "status": "complete"
            //             }
            //         ],
            //         "status": "ok"
            //     }
            // };

            // let endpoint = 'https://stg.impactorder.com/sso/status?v='+this.version+'&ra='+Math.random();
            // endpoint = 'https://www.impactorder.com/sso/status';
            /*
            options
                send explicit header
                try a post
                try catch
                response error handler
                https://remax.oktapreview.com/home/oidc_client/0oauxkb5q3QbudBXe0h7/aln5z7uhkbM6y7bMy0g7?fromHome=true
            */
        //    console.log(`endpoint ${endpoint}`);

            const t = this;

            const antiAsync = (cb) => {
                let burl = 'https://stg.impactorder.com/sso/';
                burl = 'https://www.impactorder.com/sso/';
                let meth = 'status?v='+t.version+'&ra='+Math.random();

                // Set config defaults when creating the instance
                const reportingAxios = axios.create({
                    baseURL: burl,
                });

                console.log(burl + meth);

                reportingAxios
                    .get(
                        meth
                        // ,qs.stringify({
                        //     invoice: invoice,
                        //     arCompany: arCompany,
                        //     arOfficeAgent: arOfficeAgent,
                        //     invoiceLine: invoiceLine
                        // })
                        ,{
                            // headers:{'Access-Control-Allow-Origin':'https://stg.impactorder.com'},
                            withCredentials: true
                            }
                        // "withCredentials: true" is required for cookie sending
                    )
                    .then(res => {
                        if (res.status !== 200) {
                            // NotificationManager.error(res.data["message"]);
                            // setStatus("error", "invoice")
                            // console.error('DLX Print API error', res.data["message"]);
                            cb('bad response');
                        } else {
                            // console.info('Preparing download...');
                            // NotificationManager.success(`Fulfillment report received`, 'Preparing download')
                            // setStatus("done", "invoice")
                            // fileDownload(res.data, `fulfillment_report_${invoice}.csv`);
                            console.log(res);
                            console.log(res.data);
                            cb(null,res.data);
                        }
                    })
                    .catch(err => {
                        // let error_message = err;
                        // if (err.message) error_message = err.message;
                        // if (err.data && err.data.message) error_message = err.data.message;
                        // if (err.response && err.response.data) error_message = err.response.data.message;
                        // NotificationManager.error(error_message || `There was an unexpected error`, 'Oops');
                        // setStatus("error", "invoice")
                        console.error('status error', err);
                        cb('status error',err);
                    });

            };

            antiAsync((e,d)=>{
                if (e || !d) {
                    console.log(e);
                    if (t.die_on_error) {
                        console.log('die on error');
                        return;
                    }
                    cb();
                }
                cb(d);
            });


            // const  fetchData = async (u) => {
            //     if (location.href.indexOf('localhost')!=-1) return responses.complete;
            //     let response = null;
            //     try {
            //         response = await axios.get(u, {withCredentials: true});
            //         // response = await axios.get(u);
            //     } catch (e) {
            //         console.log('endpoint fetch error',e);
            //         if (t.die_on_error) {
            //             console.log('die on error');
            //             return null;
            //         }
            //     }
            //     return response;
            // }
            // let r = await fetchData(endpoint);
            // cb(r);



        },
        updateMessage (msg) {
            const m = ['Importing Listings', 'Importing Listing Photos', 'Syncing Marketing Preferences'];
            const r = util.randomBetween(0,2);
            // console.log('rand',r);
            if (msg) {
                this.message = msg;
            } else {
                this.message = 'One moment please... ' +  m[r];
            }
        }
    },
    computed: {
        local () {
            if (location.href.indexOf('localhost')!=-1) return true;
            return true; // TODO return false when endpoints exist
        }
    }
}
</script>
