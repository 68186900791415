
const randomBetween = (min,max) => {
    return Math.floor(Math.random() * (max - min) + min);
};

const queryStringParse = (k) =>{
    let ret = {};

    let a = location.href.split('?');
    if (a.length > 1) {
        let query = a[1];
        // let a2 = [0];
        if (query.indexOf('#') != -1) {
            query = query.split('#')[0];
        }
        // console.log(query);
        a = query.split('&');
        for (let b in a) {
            // console.log('b', b);
            let a2 = a[b].split('=');
            ret[a2[0]] = a2[1];
        }
        console.log('query', ret);
    }

    if (k) {
        if (ret[k]) return ret[k];
        return '';
    }

    return ret;
}

export default {
    randomBetween,
    queryStringParse
}
